<template>
  <badge-task-form :is-edit="true"></badge-task-form>
</template>
<script>
import BadgeTaskForm from './BadgeTaskForm';

export default {
  name: 'EditBadgeTask',
  components: { BadgeTaskForm },
};
</script>
