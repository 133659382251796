<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="badgeTask">
      <el-form-item label="Name" prop="name">
        <el-input v-model="badgeTask.name"></el-input>
      </el-form-item>
      <el-form-item label="Type" prop="name">
        <el-select v-model="badgeTask.type" placeholder="Select Type">
          <el-option
            v-for="item in ['GENERAL', 'NEW_USER']"
            :key="item"
            :value="item"
            :label="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Purchase Target" prop="purchaseTarget">
        <el-input v-model="badgeTask.purchaseTarget"></el-input>
      </el-form-item>
      <el-form-item label="Boost Target" prop="boostTarget">
        <el-input v-model="badgeTask.boostTarget"></el-input>
      </el-form-item>
      <el-form-item label="Products">
        <el-select v-model="badgeTask.productIds" multiple>
          <el-option
            v-for="item in productOptions"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Color" prop="color">
        <el-input v-model="badgeTask.color"></el-input>
      </el-form-item>
      <div v-if="badgeTask.type == 'GENERAL'">
        <el-form-item label="Purchase Fill Color">
          <el-input v-model="badgeTask.purchaseColor.fillColor"></el-input>
        </el-form-item>
        <el-form-item label="Purchase Tint Color 1">
          <el-input v-model="badgeTask.purchaseColor.tintColor1"></el-input>
        </el-form-item>
        <el-form-item label="Purchase Tint Color 2">
          <el-input v-model="badgeTask.purchaseColor.tintColor2"></el-input>
        </el-form-item>
        <el-form-item label="Boost Fill Color">
          <el-input v-model="badgeTask.boostColor.fillColor"></el-input>
        </el-form-item>
        <el-form-item label="Boost Tint Color 1">
          <el-input v-model="badgeTask.boostColor.tintColor1"></el-input>
        </el-form-item>
        <el-form-item label="Boost Tint Color 2">
          <el-input v-model="badgeTask.boostColor.tintColor2"></el-input>
        </el-form-item>
      </div>
      <div v-if="badgeTask.type == 'NEW_USER'">
        <el-form-item label="New User Fill Color">
          <el-input v-model="badgeTask.newUserColor.fillColor"></el-input>
        </el-form-item>
        <el-form-item label="New User Tint Color 1">
          <el-input v-model="badgeTask.newUserColor.tintColor1"></el-input>
        </el-form-item>
        <el-form-item label="New User Tint Color 2">
          <el-input v-model="badgeTask.newUserColor.tintColor2"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="Complete Img" prop="completeImg">
        <single-upload v-model="badgeTask.completeImg"></single-upload>
      </el-form-item>
      <el-form-item label="Incomplete Img" prop="incompleteImg">
        <single-upload v-model="badgeTask.incompleteImg"></single-upload>
      </el-form-item>
      <el-form-item label="In Progress Img" prop="inProgressImg">
        <single-upload v-model="badgeTask.inProgressImg"></single-upload>
      </el-form-item>
      <el-form-item label="Badge Background Img" prop="badgeBackgroundImg">
        <single-upload v-model="badgeTask.badgeBackgroundImg"></single-upload>
      </el-form-item>
      <el-form-item label="Small Badge Img" prop="smallbadgeImg">
        <single-upload v-model="badgeTask.smallBadgeImg"></single-upload>
      </el-form-item>
      <el-form-item label="Json Reg. Img" prop="jsonRegularImg">
        <single-upload v-model="badgeTask.jsonRegularImg"></single-upload>
      </el-form-item>
      <el-form-item label="Json Success Img" prop="jsonSuccessImg">
        <single-upload v-model="badgeTask.jsonSuccessImg"></single-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">Submit</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import SingleUpload from '@/components/Upload/singleUpload';
import { fetchOptions } from '@/api/digitalProduct';
import { createBadgeTask, fetchParam } from '@/api/badge';

const defaultBadgeTask = {
  id: null,
  productIds: [],
  purchaseTarget: null,
  boostTarget: null,
  name: null,
  color: null,
  newUserColor: {
    fillColor: null,
    tintColor1: null,
    tintColor2: null,
  },
  purchaseColor: {
    fillColor: null,
    tintColor1: null,
    tintColor2: null,
  },
  boostColor: {
    fillColor: null,
    tintColor1: null,
    tintColor2: null,
  },
  type: 'GENERAL',
  completeImg: null,
  incompleteImg: null,
  inProgressImg: null,
  badgeBackgroundImg: null,
  smallBadgeImg: null,
  jsonRegularImg: null,
  jsonSuccessImg: null,
};
export default {
  name: 'BadgeTaskForm',
  components: { SingleUpload },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    fetchOptions({ badgeTaskId: this.$route.query.id }).then((response) => {
      this.productOptionsOriginal = response.data;
      this.productOptions = response.data;
    });
    if (this.isEdit) {
      fetchParam(this.$route.query.id).then((response) => {
        this.badgeTask = response.data;
      });
    }
  },
  data() {
    return {
      badgeTask: { ...defaultBadgeTask },
      productOptionsOriginal: [],
      productOptions: [],
    };
  },
  methods: {
    submit() {
      this.$confirm('是否提交数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        createBadgeTask(this.badgeTask).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          setTimeout(() => {
            location.reload();
          }, 1000);
        });
      });
    },
  },
};
</script>
